<template>
  <b-card no-body class="shadow-lg rounded" border-variant="primary">
    <div slot="header">
      <strong>Company Devices</strong>
    </div>
    <b-card-body body-border-variant="danger">
      <DeviceGroupAdd refs="create" :company-id="companyId"></DeviceGroupAdd>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import DeviceGroupAdd from './DeviceGroupAdd'

export default AdminExtension.extend({
    name: 'DeviceGroup',
    props: {
      companyId: {
        require: false,
        type: String,
      }
    },
    components: {
      DeviceGroupAdd,
    },
    computed:{
      showCompanyComponent(){
        return this.companyId != null || this.isNew
      },
    },
    data() {
        return {
          id: null,
          isEdit: false,
          isNew: false,
        }
    },
    methods: {
        onReset(){
          this.id = null
          this.isNew = false
          this.$refs.create.onReset()
        },
        onCompanySelected(company){
          this.id = company.id
        },
        onNewCompany(){
          this.isNew = true
        },
    },
})
</script>
